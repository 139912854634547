import { Component, OnInit } from '@angular/core';
import * as Odometer from 'odometer';
import * as $ from 'jquery';


@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    const odometer = new Odometer({
      el: document.querySelector('.odometer'),
      value: 1234,
      format: '(d)',
      duration: 5000
    });
    
    // $(document).ready(() => {
    //   $('.owl-carousel').owlCarousel({
    //     items: 3,
    //     loop: true,
    //     margin: 10,
    //     nav: true,
    //     navText: ["<",">"],
    //     dots: true,
    //     autoplay: true,
    //     autoplayTimeout: 3000,
    //     autoplayHoverPause: true
    //   });
    // });
    // Update odometer value
    odometer.update(5678);
  }
}
