<app-navbar-one></app-navbar-one>
<div class="main-banner-area-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center grid gap-0 row-gap-5">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content pe-3">
                            <span>
                                {{ "HEADDER.TITLE" | translate }}
                            </span>
                            <h1>
                                {{ "HEADDER.TITLE_2" | translate }}
                            </h1>
                            <p>{{ "HEADDER.DESC" | translate }}</p>

                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">{{
                                    "HEADDER.GET_START" | translate
                                }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img
                                src="assets/img/home-four/img1.png"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</div>
<!-- <section class="features-section pt-100 pb-70">

    <div class="container">
        <div class="section-title">
            <h2>Creative <span>Features</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-blueprint"></i>
                    </div>
                    <h3>Great Design</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-update"></i>
                    </div>
                    <h3>Optimal Choice</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-security"></i>
                    </div>
                    <h3>Finest Quality</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <h3>Time Saving</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="creative-section pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="creative-content">
                    <h3>How to Generate <span>Creative</span> Ideas for <span>your</span> IT Business</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus do  umsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the industry. Lorem Ipsum has been the industry's.</p>
                </div>
                <div class="skill-bar" data-percentage="75%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Developement</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="45%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Design</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="80%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Marketing</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="70%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Support</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="90%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Review</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="creative-image">
                    <img src="assets/img/about/about.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=YymWhauqjoA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Digital Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>App Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>E-commerce Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>IT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
    
    <div class="services-shape">
        <img src="assets/img/cloud.png" alt="image">
    </div>
</section>

<section class="development-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="development-image">
                    <img src="assets/img/development.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="development-text">
                    <h3>Web & Mobile <span>Development</span></h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
                <div class="development-content">
                    <div class="icon">
                        <i class="flaticon-blog"></i>
                    </div>
                    <h3>UI/UX Design</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-05dbcf">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-fec66f">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Mobile Developement</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-66a6ff">
                        <i class="flaticon-devices"></i>
                    </div>
                    <h3>Responsive Design</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="video-section">
    <div class="container">
        <div class="video-content">
            <a href="https://www.youtube.com/watch?v=YymWhauqjoA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
        </div>
    </div>
</div>

<section class="choose-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why <span>Choose</span> Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-shared-folder"></i>
                    </div>
                    <h3>Consulting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-blog"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>Page Ranking</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>Location Targeting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/choose.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="projects-section pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Latest <span>Projects</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img1.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Update & Rebrand</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img2.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Consultancy</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img3.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Digital Marketing</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img4.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img5.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Solutions</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img6.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Data Management</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img7.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>E-commerce Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-section">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Moris Jacker</h3>
                    <span>Web Developer</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Alex Maxwel</h3>
                    <span>Agent Management</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Edmond Halley</h3>
                    <span>Web Designer</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Latest</span> News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>20 March 2023</span>
                        <h3><a routerLink="/blog-details">Great Tips To Earn More Money From Digital Industry</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>25 April 2023</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img3.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>30 July 2023</span>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="audience-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img src="assets/img/audience.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3>
                        {{ "Audience.Audience_Title_1" | translate }}
                        <span>{{
                            "Audience.Audience_Title_2" | translate
                        }}</span>
                        {{ "Audience.Audience_Title_3" | translate }}
                    </h3>
                    <div class="bar"></div>
                    <p>
                        {{ "Audience.Audience_Desc_1" | translate }}
                    </p>
                    <p>
                        {{ "Audience.Audience_Desc_2" | translate }}
                    </p>
                    <p>
                        {{ "Audience.Audience_Desc_3" | translate }}
                    </p>
                    <div class="audience-btn">
                        <a routerLink="/" class="default-btn">{{
                            "Audience.Get_Started" | translate
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>
<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "OUR_SERVICES.TITLE_1_P1" | translate }}
                <span>{{ "OUR_SERVICES.TITLE_1_P2" | translate }}</span>
                {{ "OUR_SERVICES.TITLE_1_P3" | translate }}
            </h2>
            <p>
                {{ "OUR_SERVICES.TITLE_2" | translate }}
            </p>
            <div class="bar"></div>
        </div>
        <div class="row g-4 align-items-stretch">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>{{ "OUR_SERVICES.head_line_title_1" | translate }}</h3>
                    <p>
                        {{ "OUR_SERVICES.head_line_desc_1" | translate }}
                    </p>
                    <a routerLink="/services-details" class="read-btn">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>{{ "OUR_SERVICES.head_line_title_2" | translate }}</h3>
                    <p>
                        {{ "OUR_SERVICES.head_line_desc_2" | translate }}
                    </p>
                    <a routerLink="/services-details" class="read-btn">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3>{{ "OUR_SERVICES.head_line_title_3" | translate }}</h3>
                    <p>
                        {{ "OUR_SERVICES.head_line_desc_3" | translate }}
                    </p>
                    <a routerLink="/services-details" class="read-btn">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>

<section class="solutions-section pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-content-area">
                    <div class="solutions-content">
                        <h3>
                            {{ "Solutions.Solutions_Title_1" | translate }}
                            <span>{{
                                "Solutions.Solutions_Title_2" | translate
                            }}</span>
                            {{ "Solutions.Solutions_Title_3" | translate }}
                        </h3>
                        <div class="bar"></div>
                        <p>
                            {{ "Solutions.Solutions_Desc" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d5e6fe">
                            <i class="flaticon-laptop"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_1" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_1" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-fce8c9">
                            <i class="flaticon-analysis"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_2" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_2" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d3fbf9">
                            <i class="flaticon-software"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_3" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_3" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon">
                            <i class="flaticon-blog"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_4" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_4" | translate }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="solutions-image">
                    <img src="assets/img/solutions.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="design-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                <div class="design-image">
                    <img src="assets/img/design.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                <div class="design-content">
                    <h3>
                        {{ "Development.Development_Title_1" | translate }}
                        <span>{{
                            "Development.Development_Title_2" | translate
                        }}</span>
                    </h3>
                    <div class="bar"></div>
                    <p>
                        {{ "Development.Development_Desc" | translate }}
                    </p>
                    <ul class="design-list">
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_1" | translate }}
                        </li>
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_2" | translate }}
                        </li>
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_3" | translate }}
                        </li>
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_4" | translate }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>

<section class="counter-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "counter.counter_Title_1" | translate }}
                <span>{{ "counter.counter_Title_2" | translate }}</span>
                {{ "counter.counter_Title_3" | translate }}
                <span>{{ "counter.counter_Title_4" | translate }}</span>
            </h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-check"></i>
                    </div>
                    <h3><span class="odometer" data-count="2132">00</span></h3>
                    <p>{{ "counter.counter_desc_1" | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="982">00</span></h3>
                    <p>{{ "counter.counter_desc_2" | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="736">00</span></h3>
                    <p>{{ "counter.counter_desc_3" | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="359">00</span></h3>
                    <p>{{ "counter.counter_desc_4" | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "team.team_Title_1" | translate }}
                <span>{{ "team.team_Title_2" | translate }}</span>
                {{ "team.team_Title_3" | translate }}
            </h2>
            <p>
                {{ "team.team_desc" | translate }}
            </p>
            <div class="bar"></div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img1.jpg" alt="image" />
                        <ul class="social">
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>{{ "team.member_name_1" | translate }}</h3>
                        <span>{{ "team.member_jop_title_1" | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img2.jpg" alt="image" />
                        <ul class="social">
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>{{ "team.member_name_2" | translate }}</h3>
                        <span>{{ "team.member_jop_title_2" | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>

<section class="protfolio-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-tab">
                    <div class="section-title">
                        <h2>
                            {{ "OUR_PORTFOLIO.TITLE_1_P1" | translate }}
                            <span>{{
                                "OUR_PORTFOLIO.TITLE_1_P2" | translate
                            }}</span>
                            {{ "OUR_PORTFOLIO.TITLE_1_P3" | translate }}
                        </h2>
                        <p>
                            {{ "OUR_PORTFOLIO.TITLE_2" | translate }}
                        </p>
                        <div class="bar"></div>
                    </div>
                    <div class="tab schedule-list-tab">
                        <ul class="tabs">
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-internet"></i>
                                        {{ "OUR_PORTFOLIO.head_line_title_1" | translate }}</span
                                      
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-optimize"></i>
                                        {{ "OUR_PORTFOLIO.head_line_title_2" | translate }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-data"></i>   {{ "OUR_PORTFOLIO.head_line_title_3" | translate }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-software"></i>
                                        {{ "OUR_PORTFOLIO.head_line_title_4" | translate }}</span
                                    ></a
                                >
                            </li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="clients-section pt-100 pb-70">
    <div class="container-clint">
        <div class="section-title">
            <h2>
                {{ "our_clints.title_1" | translate }}
                <span>{{ "our_clints.title_2" | translate }}</span>
                {{ "our_clints.title_3" | translate }}
            </h2>
            <p>{{ "our_clints.desc" | translate }}</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_1" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_1" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_1" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_2" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_2" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_2" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon bg-fce8c9">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_3" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_3" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_3" | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</section>
