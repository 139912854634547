<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-tab">
                    <div class="section-title">
                        <h2>
                            {{ "OUR_SERVICES.TITLE_1_P1" | translate }}
                            <span>{{
                                "OUR_SERVICES.TITLE_1_P2" | translate
                            }}</span>
                            {{ "OUR_SERVICES.TITLE_1_P3" | translate }}
                        </h2>
                        <p>
                            {{ "OUR_SERVICES.TITLE_2" | translate }}
                        </p>
                        <div class="bar"></div>
                    </div>
                    <div class="tab about-list-tab text-center">
                        <ul class="tabs">
                            <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_1"
                                    | translate
                            }}</a></li>
                            <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_2"
                                    | translate
                            }}</a></li>
                            <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_3"
                                    | translate
                            }}</a></li>
                            <!-- <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_3"
                                    | translate
                            }}</a></li> -->
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="row g-4 justify-content-center align-items-stretch">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_1"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_1"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_2"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_2"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="bx bxs-ad"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_3"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_3"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_4"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_4"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row g-4 justify-content-center align-items-stretch">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_1"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_1"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_2"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_2"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="bx bxs-ad"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_3"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_3"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row g-4 justify-content-center align-items-stretch">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DESIGN_TITLE"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DESIGN_DESC"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.BRANDING_TITLE"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.BRANDING_DESC"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                            <!-- <div class="tabs_item">
                                <div class="text">
                                    <h3>
                                        How to generate your Creative Idea With
                                        IT Business
                                    </h3>
                                </div>
                                <ul class="list">
                                    <li>
                                        <i class="flaticon-tick"></i> The
                                        Philosopy of Business Analytics
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i> Fast Track
                                        Your Business
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i> Lies &
                                        Damn Lies About Your Business
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i> The
                                        Ultimate Deal on Business
                                    </li>
                                </ul>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Quis ipsum suspendisse ultrices gravida.
                                    Risus commodo viverra maecenas accumsan
                                    facilisis.
                                </p>
                                <a class="default-btn" routerLink="/"
                                    >Discover More</a
                                >
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="faq-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently <span>Asked</span> Questions</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incidiunt labore et dolore magna aliqua.
            </p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div
                    class="faq-accordion"
                    id="accordionEx"
                    role="tablist"
                    aria-multiselectable="true"
                >
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a
                                data-bs-toggle="collapse"
                                data-bs-parent="#accordionEx"
                                href="#collapseOne"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                                >What access do I have on the free plan?
                                <i class="bx bx-chevron-down"></i
                            ></a>
                        </div>
                        <div
                            id="collapseOne"
                            class="collapse show"
                            role="tabpanel"
                            data-bs-parent="#accordionEx"
                        >
                            <div class="card-body">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-parent="#accordionEx"
                                href="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                >What access do I have on a free trial?
                                <i class="bx bx-chevron-down"></i
                            ></a>
                        </div>
                        <div
                            id="collapseTwo"
                            class="collapse"
                            role="tabpanel"
                            data-bs-parent="#accordionEx"
                        >
                            <div class="card-body">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-parent="#accordionEx"
                                href="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                                >Does the price go up as my team gets larger?
                                <i class="bx bx-chevron-down"></i
                            ></a>
                        </div>
                        <div
                            id="collapseThree"
                            class="collapse"
                            role="tabpanel"
                            data-bs-parent="#accordionEx"
                        >
                            <div class="card-body">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-parent="#accordionEx"
                                href="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                                >How can I cancel my subscription?
                                <i class="bx bx-chevron-down"></i
                            ></a>
                        </div>
                        <div
                            id="collapseFour"
                            class="collapse"
                            role="tabpanel"
                            data-bs-parent="#accordionEx"
                        >
                            <div class="card-body">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-parent="#accordionEx"
                                href="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                                >Can I pay via an Invoice?
                                <i class="bx bx-chevron-down"></i
                            ></a>
                        </div>
                        <div
                            id="collapseFive"
                            class="collapse"
                            role="tabpanel"
                            data-bs-parent="#accordionEx"
                        >
                            <div class="card-body">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>
