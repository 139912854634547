import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public subscribeForm!: UntypedFormGroup;

  constructor(public formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.subscribeForm = this.formBuilder.group({
      email: ["", Validators.required],
    });
  }
  public onSubscribeFormSubmit(values: any): void {
    if (this.subscribeForm.valid) {
      console.log(values);
      const message = `'${values.email}' Need To Subscribe`;
      const phoneNumber = '201270888338'; // Replace with your WhatsApp number
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      
      window.open(whatsappUrl, '_blank');
    }
    alert("Message has been sent")
    this.subscribeForm.reset();
  }
}
