<section class="footer-section pt-100 pb-70">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="subscribe-content">
                        <h2>{{ "FOOTER.SUBSCRIBE_TITLE" | translate }}</h2>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <form
                        [formGroup]="subscribeForm"
                        (ngSubmit)="onSubscribeFormSubmit(subscribeForm.value)"
                        class="newsletter-form d-flex"
                    >
                        <input
                            type="email"
                            class="input-newsletter"
                            [placeholder]="
                                'FOOTER.SUBSCRIBE_PLACEHOLDER' | translate
                            "
                            formControlName="email"
                        />
                        <button type="submit">
                            {{ "FOOTER.SUBSCRIBE_BOTTON" | translate }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{ "FOOTER.FOOTER_TITLE" | translate }}</h3>
                    </div>
                    <p>{{ "FOOTER.FOOTER_DESC" | translate }}</p>
                    <ul class="footer-social">
                        <li>
                            <a href="https://wa.me/966559634398" target="_blank"
                                ><i class="bx bxl-whatsapp"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://web.facebook.com/omaagency/"
                                target="_blank"
                                ><i class="flaticon-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="https://x.com/Oma_agency" target="_blank"
                                ><i
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 512 512"
                                    >
                                        <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                        <path
                                            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                                        /></svg></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/in/omamarketingco/"
                                target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/omamarketingco"
                                target="_blank"
                                ><i class="flaticon-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{ "FOOTER.Important_Links" | translate }}</h3>
                    </div>
                    <ul class="footer-quick-links p-0">
                        <li>
                            <a routerLink="/about">{{
                                "FOOTER.ABOUT_US" | translate
                            }}</a>
                        </li>
                        <li>
                            <a routerLink="/projects">{{
                                "FOOTER.PROJECTS" | translate
                            }}</a>
                        </li>
                        <li>
                            <a routerLink="/services">{{
                                "FOOTER.OUR_SERVICES" | translate
                            }}</a>
                        </li>
                        <li>
                            <a routerLink="/blog-grid">{{
                                "FOOTER.BLOG" | translate
                            }}</a>
                        </li>
                        <li>
                            <a routerLink="/contact">{{
                                "FOOTER.CONTACT" | translate
                            }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{ "FOOTER.CONTACT" | translate }}</h3>
                    </div>
                    <div class="footer-info-contact d-flex">
                        <i class="flaticon-phone-call icon"></i>
                        <div>
                            <h3>{{ "FOOTER.PHONE" | translate }}</h3>
                            <div class="row">
                                <div class="col-md-4">
                                    <span
                                        ><a href="tel:+201097774231">{{
                                            "FOOTER.PHONE_NUM_1" | translate
                                        }}</a></span
                                    >
                                </div>
                                <div class="col-md-4">
                                    <span
                                        ><a href="tel:+201220733684">{{
                                            "FOOTER.PHONE_NUM_2" | translate
                                        }}</a></span
                                    >
                                </div>
                                <div class="col-md-4">
                                    <span
                                        ><a href="tel:+966559634398">{{
                                            "FOOTER.PHONE_NUM_3" | translate
                                        }}</a></span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-info-contact d-flex">
                        <i class="flaticon-envelope icon"></i>
                        <div>
                            <h3>{{ "FOOTER.E_MAIL" | translate }}</h3>
                            <span
                                ><a href="">{{
                                    "FOOTER.E_MAIL_OMA" | translate
                                }}</a></span
                            >
                        </div>
                    </div>
                    <div class="footer-info-contact d-flex">
                        <i class="flaticon-pin icon"></i>
                        <div>
                            <h3>{{ "FOOTER.ADDRESS" | translate }}</h3>
                            <span
                                ><a href="#" target="_blank">{{
                                    "FOOTER.ADDRESS_1" | translate
                                }}</a></span
                            >
                            <br />
                            <span
                                ><a href="#" target="_blank">{{
                                    "FOOTER.ADDRESS_2" | translate
                                }}</a></span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-12 text-center">
                    <p>
                        {{ "FOOTER.Copyright_1" | translate }}
                        <Span class="oma">{{
                            "FOOTER.Copyright_2" | translate
                        }}</Span>
                        {{ "FOOTER.Copyright_3" | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="bx bx-chevron-up"></i><i class="bx bx-chevron-up"></i>
</div>

<!-- <div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>About Us</h2>
                <p>
                    We believe brand interaction is key in communication. Real
                    innovations and a positive customer experience are the heart
                    of successful communication. No fake products and services.
                    The customer is king, their lives and needs are the
                    inspiration.
                </p>
            </div>
        </div>
        <div class="sidebar-contact-feed">
            <h2>Contact</h2>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    class="form-control"
                                    placeholder="Your Name"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    class="form-control"
                                    placeholder="Your Email"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="phone_number"
                                    id="phone_number"
                                    class="form-control"
                                    placeholder="Your Phone"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="msg_subject"
                                    id="msg_subject"
                                    class="form-control"
                                    placeholder="Your Subject"
                                />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea
                                    name="message"
                                    class="form-control"
                                    id="message"
                                    cols="30"
                                    rows="6"
                                    placeholder="Your Message"
                                ></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="send-btn-one">
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="sidebar-contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:+0881306298615">+088 130 629 8615</a>
                        <span>OR</span>
                        <a href="mailto:hello&#64;avrax.com"
                            >hello&#64;avrax.com</a
                        >
                    </h2>
                    <ul class="social">
                        <li>
                            <a href="#" target="_blank"
                                ><i class="flaticon-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="flaticon-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="flaticon-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="flaticon-pinterest"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn">
            <i class="flaticon-cancel"></i>
        </span>
    </div>
</div> -->
