<app-navbar-one></app-navbar-one>

<div class="main-banner-area-four ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span>Digital Marketing</span>
                            <h1>Digital Agency & Marketing</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing incididunt ut laboredolore magna aliqua elsed  tempomet, consectetur adipiscing.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/home-four/img1.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>

<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Digital Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>App Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>E-commerce Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>IT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="audience-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img src="assets/img/audience.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3>Engaging New <span>Audience</span> Through Smart Approach</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <div class="audience-btn">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="solutions-section pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-content-area">
                    <div class="solutions-content">
                        <h3>Our <span>Featured</span> Solutions</h3>
                        <div class="bar"></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon">
                            <i class="flaticon-blog"></i>
                        </div>
                        <h3>Programmatic Advertising</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d3fbf9">
                            <i class="flaticon-software"></i>
                        </div>
                        <h3>Strategy & Research</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-fce8c9">
                            <i class="flaticon-analysis"></i>
                        </div>
                        <h3>Design & Developement</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d5e6fe">
                            <i class="flaticon-laptop"></i>
                        </div>
                        <h3>Branding & Marketing</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="solutions-image">
                    <img src="assets/img/solutions.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="design-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="design-image">
                    <img src="assets/img/design.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="design-content">
                    <h3>Design <span>Development</span></h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices  Lorem Ipsum is simply dummy tex printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    <ul class="design-list">
                        <li><i class="flaticon-check"></i> Web Development</li>
                        <li><i class="flaticon-check"></i> Laravel Design</li>
                        <li><i class="flaticon-check"></i> App Development</li>
                        <li><i class="flaticon-check"></i> Responsive Design</li>
                        <li><i class="flaticon-check"></i> UI/UX Design</li>
                        <li><i class="flaticon-check"></i> Unique Design</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="counter-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>We have Completed <span>950+</span> Projects <span>Successfully</span></h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-check"></i>
                    </div>
                    <h3><span class="odometer" data-count="950">00</span></h3>
                    <p>Completed Project</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="150">00</span></h3>
                    <p>Happy Clients</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="550">00</span></h3>
                    <p>Multi Service</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="750">00</span></h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Williams Halimton</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Juhon Dew</h3>
                        <span>CEO</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Pricing</span> Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>29<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-cancel"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Standard</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>79<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> Visitor Info</li>
                        <li><i class="flaticon-checked"></i> Quick Responses</li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>99<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-checked"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="protfolio-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Impressive</span> Portfolio</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img1.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Creative Web Develop</h3>
                        <span>Web Design</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img2.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Digital Services</h3>
                        <span>App Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img3.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Complex Design</h3>
                        <span>Software Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img4.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Creative Web Develop</h3>
                        <span>React Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img5.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Digital Services</h3>
                        <span>E-commerce Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img6.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Complex Design</h3>
                        <span>Software Engineering</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-section">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Moris Jacker</h3>
                    <span>Web Developer</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Alex Maxwel</h3>
                    <span>Agent Management</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Edmond Halley</h3>
                    <span>Web Designer</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Latest</span> News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>20 March 2023</span>
                        <h3><a routerLink="/blog-details">Great Tips To Earn More Money From Digital Industry</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>25 April 2023</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img3.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>30 July 2023</span>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>