<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="protfolio-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-tab">
                    <div class="section-title">
                        <h2>
                            {{ "OUR_PORTFOLIO.TITLE_1_P1" | translate }}
                            <span>{{
                                "OUR_PORTFOLIO.TITLE_1_P2" | translate
                            }}</span>
                            {{ "OUR_PORTFOLIO.TITLE_1_P3" | translate }}
                        </h2>
                        <p>
                            {{ "OUR_PORTFOLIO.TITLE_2" | translate }}
                        </p>
                        <div class="bar"></div>
                    </div>
                    <div class="tab schedule-list-tab">
                        <ul class="tabs">
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-internet"></i>
                                        {{ "OUR_PORTFOLIO.head_line_title_1" | translate }}</span
                                      
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-optimize"></i>
                                        {{ "OUR_PORTFOLIO.head_line_title_2" | translate }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-data"></i>   {{ "OUR_PORTFOLIO.head_line_title_3" | translate }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-software"></i>
                                        {{ "OUR_PORTFOLIO.head_line_title_4" | translate }}</span
                                    ></a
                                >
                            </li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>