<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <button class="navbar-toggler" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" routerLink="/">
                <img class="logoo" src="assets/img/logo.png" alt="logo" />
            </a>

            <div class="collapse navbar-collapse">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link"
                            >{{ "NAV.HOME" | translate }}
                        </a>
                    </li>

                    <li
                        class="nav-item"
                        data-bs-toggle="collapse"
                        data-target=".navbar-collapse.show"
                    >
                        <a
                            routerLink="/about"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >{{ "NAV.ABOUT_US" | translate }}</a
                        >
                    </li>

                    <li class="nav-item">
                        <a routerLink="/services-one" class="nav-link">{{
                            "NAV.OUR_SERVICES" | translate
                        }}</a>
                    </li>

                    <li class="nav-item">
                        <a routerLink="/projects-one" class="nav-link"
                            >{{ "NAV.PROJECTS" | translate }}
                        </a>
                    </li>

                    <li
                        class="nav-item"
                        data-bs-toggle="collapse"
                        data-target=".navbar-collapse.show"
                    >
                        <a
                            routerLink="/contact"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >{{ "NAV.CONTACT" | translate }}</a
                        >
                    </li>
                </ul>
            </div>
            <div class="others-options text-center">
                <div>
                    <button
                        class="btn p-0"
                        (click)="toggleLanguage()"
                        type="button"
                    >
                        <img
                            *ngIf="selectedLanguage === 'en'"
                            src="assets/flags/ar.svg"
                            alt="English"
                            style="width: 24px; height: 16px"
                        />
                        <img
                            *ngIf="selectedLanguage === 'ar'"
                            src="assets/flags/en.svg"
                            alt="Arabic"
                            style="width: 24px; height: 16px"
                        />
                    </button>
                </div>
            </div>
        </nav>
    </div>
</div>
